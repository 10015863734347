<template>
  <head>
    <title>PHOTO MANAGEMENT - RUNNING.IN.TH ค้นหาใบหน้า ด้วยรอยยิ้ม</title>
    <!-- <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" /> -->
  </head>
  <router-view ></router-view>
</template>

<script>
export default {
  name: "App"
};
</script>

